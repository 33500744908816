/** @jsx jsx */
import { ReactElement, useState, useEffect } from 'react';
import { graphql } from 'gatsby';
import { jsx } from 'theme-ui';
import pageNotFound from '../../assets/images/pageNotFound.webp';
import FourOFourDisplay from '@sprinklrjs/website-components/components/fourOFourDisplay';
import { useI18next } from 'gatsby-plugin-react-i18next';
import { resolveLocale } from '@sprinklr/shared-lib/utils';
import ExploreLayout from '../../components/ExploreLayout';

export const custom404Query = graphql`
  query (
    $nodeLocale: String!
    $language: String!
    $isHeaderV3Enabled: Boolean!
  ) {
    ...LocalesQuery
    ...HeaderFooterSocialQuery
    ...ExploreSiteMetadataQuery
  }
`;

export default function Custom404Page({ data, pageContext }): ReactElement {
  const { language, t } = useI18next();
  const [paintToDom, setPaintToDom] = useState(false);
  useEffect(() => {
    if (!!language && !paintToDom) setPaintToDom(true);
  }, [language]);

  if (!paintToDom) return <div></div>;

  const isPageRTL = language === 'ar';

  const { headerV3, footerV3, allContentfulGlobalHeaderFooter, site } = data;
  const nodeLocale = resolveLocale(language);
  const hostLanguageCode = language === 'en' ? '' : `${language}/`;
  const baseHost = `https://www.sprinklr.com/`;
  const host = `${baseHost}${hostLanguageCode}`;
  const title = t(
    'You’ve reached the wall of lost pages — let’s find a new way',
  );
  const metadata = {
    seoTitle: '404 – Let’s Find You a New Way | Sprinklr',
    seoDescription: {
      seoDescription:
        'Oops! Looks like we hit a dead end. Let’s find a new way! Visit Sprinklr home or explore our solutions for enterprise marketing and customer service teams.',
    },
  };
  const links = [
    {
      label: t('Sprinklr home'),
      url: host,
    },
    {
      label: t('Demo center'),
      url: `${baseHost}demo-center/`,
    },
    {
      label: t('Solutions for marketing teams'),
      url: `${host}teams/marketing/`,
    },
    {
      label: t('Solutions for customer service teams'),
      url: `${host}teams/customer-service/`,
    },
    {
      label: t('Talk to an expert'),
      url: `${baseHost}contact-us/`,
    },
  ];

  return (
    <ExploreLayout
      context={{
        ...pageContext,
        nodeLocale,
        site,
        isGradientBackgroundEnabled: false,
      }}
      site={site}
      headerV3={headerV3}
      footerV3={footerV3}
      globalSkeletonData={allContentfulGlobalHeaderFooter?.edges[0]?.node}
      isPageRTL={isPageRTL}
      footerV3ClassName={'bg-snowWhite'}
      metadata={metadata}
    >
      <FourOFourDisplay links={links} title={title} imageSrc={pageNotFound} />
    </ExploreLayout>
  );
}
