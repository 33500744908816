import React from 'react';
import { cn } from '../../tailwind';

const Background = () => {
  return (
    <>
      <div
        className={cn(
          'w-full flex h-6 tablet-sm:h-12 desktop-sm:h-[72px] desktop-sm:h-24 absolute inset-0',
        )}
      >
        <div className="absolute h-full left-[-10%] w-[20%] rounded-full -z-10 bg-deepSkyBlue transform-gpu blur-[30px] tablet-sm:blur-[60px]" />
        <div className="absolute h-full left-[5%] w-[50%] rounded-full -z-10 bg-primarySkyBlue transform-gpu blur-[30px] tablet-sm:blur-[60px]" />
        <div className="absolute h-full left-[55%] w-[50%] rounded-full -z-10 bg-primaryEarthGreen transform-gpu blur-[30px] tablet-sm:blur-[60px]" />
      </div>
      <div className="absolute -z-10 top-[210px] tablet-sm:top-[300px] desktop-sm:top-[390px] desktop-lg:top-[455px] w-full h-full bg-snowWhite"></div>
    </>
  );
};

export default Background;
