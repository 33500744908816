import React from 'react';
import { Link } from './types';
import { cn } from '../tailwind';
import Background from './components/Background';

type FourOFourDisplayProps = {
  title: string;
  links: Link[];
  imageSrc: string;
};

const FourOFourDisplay = ({
  title,
  links,
  imageSrc,
}: FourOFourDisplayProps) => {
  return (
    <div className="relative pt-14 tablet-sm:pt-[84px] desktop-sm:pt-[120px] desktop-lg:pt-[140px] z-0 overflow-hidden">
      <Background />
      <div
        className={cn(
          'flex flex-col gap-4 tablet-sm:flex-row tablet-sm:gap-8 desktop-sm:gap-14 desktop-lg:gap-24 mx-auto',
          'max-w-[312px] tablet-sm:max-w-[688px] desktop-sm:max-w-[930px] desktop-lg:max-w-[1234px]',
        )}
      >
        <img
          src={imageSrc}
          className="max-tablet-sm:mx-auto w-[262px] tablet-sm:w-[344px] desktop-sm:w-[468px] desktop-lg:w-[542px]"
        />
        <div
          className={cn(
            'border border-lightGrey h-fit bg-white p-4 rounded-[8px] max-tablet-sm:text-center tablet-sm:p-6',
            'desktop-sm:p-8 desktop-sm:rounded-[16px] desktop-lg:p-10 desktop-lg:rounded-[20px]',
          )}
        >
          <h2
            className={cn(
              'text-mobile-sm/heading-6 tablet-sm:text-tablet-sm/heading-4',
              'desktop-sm:text-tablet-sm/heading-3 desktop-lg:text-desktop-large/heading-3',
            )}
          >
            {title}
          </h2>
          <div
            className={cn(
              'flex flex-col gap-3 desktop-sm:gap-4 desktop-lg:gap-6',
              'mt-4 tablet-sm:mt-6 desktop-sm:mt-8',
            )}
          >
            {links.map(link => (
              <React.Fragment key={link.label}>
                <a
                  href={link.url}
                  className={cn(
                    'uppercase text-deepSkyBlue',
                    'text-mobile-sm/overline-3 tablet-sm:text-tablet-sm/overline-4 desktop-sm:text-tablet-sm/overline-2 desktop-lg:text-desktop-large/overline-2',
                  )}
                >
                  {link.label}
                </a>
              </React.Fragment>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FourOFourDisplay;
